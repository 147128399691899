import React, { Component, createRef, FC, useEffect } from 'react'
import {observer} from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { confirmAlert } from '../utils/ConfirmAlert'

import { t, jt } from 'ttag'

import { Root } from '../../models3/Root'
import './Passage.css'
import PassageEditor from './PassageEditor'
import { displayError, systemError } from '../utils/Errors'
import { Passage, PassageSegmentApproval } from '../../models3/ProjectModels'
import { VideoCache } from '../../models3/VideoCache'
import { PassageNotificationList } from '../notifications/Notifications'
import { CheckCircle, CheckIcon, ThinCircleIcon } from '../utils/Icons'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { MemberDisplay } from '../utils/MemberDisplay'
import { fmt } from '../utils/Fmt'
import { DisplayStatusPieChart } from '../status/TaskSelectorDropdown'

import _debug from "debug"
 let log = _debug('sltt:Passage') 

interface IPassageView {
    rt: Root,
    passage: Passage,
    editing: boolean,
    setEditing: (editing: boolean) => void,
    showEditingIcons: boolean,
}
interface ResizableDivState {
    width: number
}

class PassageView extends Component<IPassageView, ResizableDivState> {
    private divRef = createRef<HTMLDivElement>()
    private resizeObserver: ResizeObserver | null = null
    @observable percent = 0
    @observable droptarget = false
    
    @observable uploadMessage = ''
    buttonRef = React.createRef<HTMLButtonElement>()
    @observable nameOverflowed = false
    uploadVideoUrl = ''

    timer: any

    constructor(props: IPassageView) {
        super(props)
        this.state = { width: 0 }
        makeObservable(this);
        this.onDelete = this.onDelete.bind(this)
        this.setNameOverflowed = this.setNameOverflowed.bind(this)
    }

    componentDidMount() {
        this.timer = setInterval(() => this.fetchUploadStatus(), 2000)
        this.setNameOverflowed()
        this.resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === this.divRef.current) {
                    this.setState({ width: entry.contentRect.width })
                }
            }
        })

        if (this.divRef.current) {
            this.resizeObserver.observe(this.divRef.current)
        }
    }

    componentWillUnmount() {
        this.clearMyTimer()
        if (this.resizeObserver) {
            this.resizeObserver.disconnect()
        }
    }

    setNameOverflowed() {
        let { current } = this.buttonRef
        if (current) {
            this.nameOverflowed = current.scrollWidth > current.offsetWidth
        }
    }

    componentDidUpdate() {
        this.setNameOverflowed()
    }

    clearMyTimer() {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    }

    /**
     * Set uploadMessage to contain upload status.
     * Empty if current video does not need uploaded or has completed upload.
     */
    fetchUploadStatus() {
        let { rt, passage } = this.props
        let { passage: _passage, passageVideo } = rt

        let videoUrl = ''
        if (_passage && passage._id === _passage._id) {
            // If this is the currently selected passage, use the url for the selected video
            videoUrl = (passageVideo && passageVideo.url) || ''
        } else {
            // Otherwise use the url for the latest video for this passage
            if (passage.videos.length) {
                videoUrl = passage.videos.slice(-1)[0].url
            }
        }

        // If this is the same url we check last time and it had no status
        // it is unnecessary to check again.
        if (videoUrl === this.uploadVideoUrl && this.uploadMessage === '') return

        if (!videoUrl) {   // check for no current video
            this.uploadVideoUrl = ''
            this.uploadMessage = ''
            return
        }
        
        this.uploadVideoUrl = videoUrl
        VideoCache.queryVideoUpload(videoUrl)
            .then(uploadMessage => { this.uploadMessage = uploadMessage })
            .catch((error: any) => {
                this.clearMyTimer()
                systemError(error)
            })
    }

    render() {
        const { width } = this.state
        let { rt, passage, editing, setEditing, showEditingIcons } = this.props
        let { portion, iAmTranslator, useMobileLayout } = rt
        let { onDelete, buttonRef, nameOverflowed, uploadMessage, onClick } = this

        if (!portion) {
            return null
        }

        return (
            <div className={`passage-box`} ref={this.divRef}>
                <div className='passage-name' data-portion-name={portion.name /* mobx rerender */}>
                    <DisplayStatusPieChart status={passage.task} plan={rt.project.plans[0]} />
                    <PassageName {...{ rt, passage, nameOverflowed, onClick, buttonRef, width }} />
                    <div className="passage-notifications">
                        <PassageNotificationList rt={rt} passage={passage} />
                    </div>
                    <PassageApprovalStatus {...{ rt, passage }} />
                    <div className="passage-menu-container">
                        {iAmTranslator && !useMobileLayout && showEditingIcons && <PassageMenu {...{ passage, setEditing, onDelete }} />}
                    </div>
                </div>
                {uploadMessage && 
                    <div className="passage-upload-status" > {uploadMessage} </div>
                }
            </div>
        )
    }

    done() {
        this.props.setEditing(false)
    }

    onClick = () => {
        // e.preventDefault()  is this needed???
        this.setPassage()
    }

    onEdit() {
        //e.preventDefault()
        this.props.setEditing(true)
    }

    onDelete() {
        //e.preventDefault()
        log('onDelete')
        let { rt, passage } = this.props
        let { portion } = rt

        this.confirmDeletion(() => { 
            let { _id } = passage
            if (rt.passage && rt.passage._id === _id) {
                rt.setPassage(null)
            }
            
            portion!.removePassage(_id) 
        })
    }

    confirmDeletion(doDeletion: () => void) {
        // No need to confirm, if no video has been recorded yet
        let { passage } = this.props
        if (!passage.videod()) { doDeletion(); return }

        confirmAlert({
                    title: /* translator: important */ t`Delete video!?`,    
                    message: /* translator: important */ t`Video has already been recorded for this passage.`,
                    confirmLabel: /* translator: important */ t`Delete video!`,
                    cancelLabel: /* translator: important */ t`Keep video.`,
                    onConfirm: doDeletion, 
                    onCancel: () => {}, 
                })
    }

    setPassage() {
        let { rt, passage } = this.props
        rt.setPassage(passage)
            .then(() => window.scrollTo(0, 0)) // Scroll to top of page when new passage selected
            .catch((err: any) => displayError(err))
    }
}

type PassageNameProps = {
    rt: Root,
    passage: Passage,
    nameOverflowed: boolean,
    buttonRef: React.RefObject<HTMLButtonElement>,
    onClick: () => void,
    width: number,
}

const PassageName: FC<PassageNameProps> = observer(({ rt, passage, nameOverflowed, buttonRef, onClick, width }) => {
    let passageIsSelected = rt.passage && passage && (rt.passage.name === passage.name)

    useEffect(() => {
        passageIsSelected && buttonRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
        })
    }, [passageIsSelected])

    let buttonClassName = 'btn passage-button' +
        (passageIsSelected ? ' passage-selected' : '') +
        (passage.videos.length > 0 ? ' passage-video-present' : '')

    let taskAssignmentDisplay = TaskAssignmentDisplay({ rt, passage })
    let hashtagsDisplay = HashtagsDisplay({ rt, passage })
    let portion = rt.project.findPortion(passage._id)
    let portionName = portion?.name ?? '*Missing*'

    let passageName = rt.hashtag ? portionName + '/' + passage.name : passage.name
    let passageNameDisplay = <div>{passageName}</div>
    
    let tooltip = (
        <Tooltip id='passage-tooltip'>
            {passageNameDisplay}
            {hashtagsDisplay}
            {taskAssignmentDisplay}
        </Tooltip>
    )

    return (
        <OverlayTrigger
            overlay={tooltip}
            placement='bottom'
            delayShow={nameOverflowed ? 0 : 1000}
        >
            <div style={{ paddingLeft: 4 }}>
                <button className={buttonClassName} style={{ fontSize: '112%' }} ref={buttonRef} onClick={onClick} >
                    {maybeTruncatePassageName(passageName, width)}
                </button>
            </div>
        </OverlayTrigger>
    )
})

type PassageMenuProps = {
    passage: Passage,
    setEditing: (editing: boolean) => void,
    onDelete: () => void,
}

const PassageMenu: FC<PassageMenuProps> = ({ passage, setEditing, onDelete}) => (
    <div className="passage-menu">
        <span className="passage-menu-button"
                data-toggle="tooltip" 
                data-id={'edit-' + passage.name} 
                title={/* translator: important */ t`Edit passage information.`} 
                onClick={() => setEditing(true)}>
                    <i className="fas fa-fw fa-pencil-alt"></i>
        </span>
        <span className="passage-menu-button"
                        data-toggle="tooltip" 
                        data-id={'delete-' + passage.name} 
                        title={/* translator: important */ t`Delete passage.`}    
                        onClick={onDelete}>
                <i className="fas fa-fw fa-trash-alt"></i>
            </span>
    </div>
)

/* Passage segment approvals have two components: circle and check mark.
 * This yields 4 states.
 * The overall approval for video has the check mark iff all the individual segment approvals
 * have the check mark.
 * The overall approval for the video has the circle iff all the individual segment approvals
 * have the circle.
 */
export function overallApprovalState(approvalStates: PassageSegmentApproval[]) {
    if (!approvalStates.length) {
        return PassageSegmentApproval.State0
    }

    const hasState1 = (state: PassageSegmentApproval) => state === PassageSegmentApproval.State1 || state === PassageSegmentApproval.State3
    const hasState2 = (state: PassageSegmentApproval) => state === PassageSegmentApproval.State2 || state === PassageSegmentApproval.State3
    const hasState3 = (state: PassageSegmentApproval) => state === PassageSegmentApproval.State3

    if (approvalStates.every(hasState3)) { return PassageSegmentApproval.State3 }
    if (approvalStates.every(hasState2)) { return PassageSegmentApproval.State2 }
    if (approvalStates.every(hasState1)) { return PassageSegmentApproval.State1 }

    return PassageSegmentApproval.State0
}

type PassageApprovalStatusProps = {
    rt: Root,
    passage: Passage,
}

const PassageApprovalStatus: FC<PassageApprovalStatusProps> = observer(({ rt, passage }) => {
    let { passageVideo } = rt
    let currentDraft = passage.videosNotDeleted.slice(-1)[0] // Default to latest video draft
    // If this is the currently selected passage, show the status for the currently selected passageVideo draft
    if (passage._id === rt.passage?._id && passageVideo) {
        currentDraft = passageVideo
    }

    let approvalStates = currentDraft?.visibleSegments(passage).map(seg => seg.approved) || []
    let approvalState = overallApprovalState(approvalStates)

    let options = [
        <span className='passage-menu-item sl-fa-button'>&mdash;</span>,
        <CheckIcon className='passage-menu-item' tooltip='' />,
        <ThinCircleIcon className='passage-menu-item' tooltip='' />,
        <CheckCircle className='passage-menu-item' tooltip='' />,
    ]
    let icon = (
        <div className='margin-right-7px'>
            {options[approvalState]}
        </div>
    )
    return (
        <>
            {approvalState > PassageSegmentApproval.State0 && icon}
        </>
    )
})

type HashtagsDisplayProps = {
    rt: Root,
    passage: Passage,
}

const HashtagsDisplay: FC<HashtagsDisplayProps> = ({ rt, passage }) => {
    let { hashtags } = passage
    if (!hashtags) return null

    return (<div> {t`Hashtags`}: {hashtags} </div>)
}

type TaskAssignmentDisplayProps = {
    rt: Root,
    passage: Passage,
}

const TaskAssignmentDisplay: FC<TaskAssignmentDisplayProps> = ({ rt, passage }) => {
    let { project } = rt
    let { assignee } = passage

    let assignedMember = project.findMember(assignee)
    if (!assignedMember) return null

    let plan = project.plans.length ? project.plans[0] : null
    if (!plan) return null

    let task = plan.tasks.find(t => t.id === passage.task)
    if (!task) return null

    let memberDisplay = <MemberDisplay 
        key={passage.task} 
        member={assignedMember} 
        imageSize='small' 
        hideImage={assignedMember.imageUrl === ''} />
    return (
        <div>
            <div>{/* translator: important */ t`Current task: ${task.displayedName}`}</div>
            <div>{/* translator: important */ jt`Assigned to: ${memberDisplay}`}</div>
        </div>
    )
}

const maybeTruncatePassageName = (passageName: string, width: number): string => {
    const maxLength = Math.floor((width - 50) / 9)
    return passageName.length > maxLength ? passageName.slice(0, maxLength) + '\u2026' : passageName // \u2026 is the elipsis character ...
}

// CRASHING when I try to use MouseHoveringDetection
//export default MouseHoveringDetection(Passage)

export default observer(PassageView)