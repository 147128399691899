import { IDB, IDBAcceptor, IDBModDoc, IDBObject } from './DBTypes'

export class MemoryDB implements IDB {
    objs: any[] = []
    username = 'bob@gmail.com'
    lastSlice = 0
    nextId = 0
    date = new Date(Date.now())
    acceptor?: IDBAcceptor

    constructor(
        private fnGetDate?: () => string
    ) {
    }

    getMaxToSyncSize() { 
        return 80000 
    }

    // Don't bother debouncing requests, since this database doesn't access a persistent database.
    submitChange(obj: IDBModDoc) {
        this.put(obj)
    }

    async put (obj: IDBObject) {
        this.objs.push(obj)
        this.acceptor!.accept(obj)
    }

    async get(seqStart?: number, seqEnd?: number): Promise<IDBModDoc[]> {
        throw Error('TBD')
    }

    async doSync() {}

    async deleteDB() {}

    disconnect() {}

    getRemoteSeq() { return 0 }

    kickoffSync(immediate?: boolean) {
    }

    getNewId(existing: any[], date: Date, tag?: string) {
        tag = tag || ''

        // For a real database we would create a random id
        //let id = (((1 + Math.random()) * 0x1000000) | 0).toString(16).substring(1)

        // But since the DB is used for unit testing only we want a repeatable set of ids
        let id = this.nextId.toString(16).padStart(10, '0')
        this.nextId = this.nextId + 1

        return tag + id
    }

    getDate(): string {
        if (this.fnGetDate) return this.fnGetDate()
        // mainly for tests.
        if (this.nextId === 0) {
            // somehow Date.now() results in 2020 Oct date in tests
            // that provides a stable starting point for snapshots
            this.date = new Date(Date.now())
        } else {
            // increment each date by 1 min 20 milliseconds
            this.date = new Date(this.date.getTime() + 60020)
        }

        let date = this.date.toISOString().slice(0, 19)
        date = date.replace('T', ' ')
        date = date.slice(0, -3)
        return date
    }

    reset(nextId: number) {
        this.nextId = nextId
        this.date = new Date(Date.now())
    }
    
    async delete(startKey = '', endKey = '') {
        throw Error('TBD')
    }

    /**
     * Returns new db objects since the last time slice() was called. Weird.
     */
    slice() {
        let result = this.objs.slice(this.lastSlice)
        this.lastSlice = this.objs.length
        return result
    }

    async initialize(acceptor: IDBAcceptor, progress: (message: string) => void) {
        this.acceptor = acceptor
        return 1
    }
    
    // Remove 'db' component from snapshot, it is not needed or snapshotable
    static getSnapshotSerializer() {
        return {
            test: (val: any) => val && val.hasOwnProperty && val.hasOwnProperty('db'),
            print: (val: any, serialize: any) => {
                let v = Object.assign({}, val)
                delete v['db']
                return serialize(v)
            }
        }
    }
}
