
export function generate4DigitHex(): string {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
}

function generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export function generate4DigitPaddedNumber(): string {
    return generateRandomNumber(0, 9999).toString().padStart(4, '0')
}
