import { IDBOperations, NextDbItem }  from '../DBTypes'

export const iterateAndDo = async (
    db: IDBOperations,
    startKeyInclusive: number | string,
    shouldStop: (item: NextDbItem) => boolean,
    doEach: (item: NextDbItem) => Promise<void>
) => {
    const dbIterator = db.getIterator(startKeyInclusive)
    let nextItem: NextDbItem
    try {
        for (
            nextItem = await dbIterator.next();
            (nextItem.key !== undefined && nextItem.key !== null && !shouldStop(nextItem));
            nextItem = await dbIterator.next()
        ) {
            await doEach(nextItem)
        }
    } finally {
        dbIterator && await dbIterator.end()
    }
}
