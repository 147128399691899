import _, { sortBy } from 'underscore'
import { t } from 'ttag'
import _debug from 'debug'

import { DBChangeNotifier } from './DBChangeNotifier'

const log = _debug('sltt:Levelup')
const dbg = _debug('slttdbg:Levelup')

import { fmt, s } from '../components/utils/Fmt'
import { DBEntry, IDB, IDBAcceptor, IDBItem, IDBModDoc, IDBObject, IDBOperations, IDBSyncApi, IRemoteDBItem, MAX_LAN_SEQ, MAX_LOCAL_SEQ, MIN_LAN_SEQ, MIN_LOCAL_SEQ, NonretriableSyncFailure, maxLocalSeqOld, minLocalSeqOld } from './DBTypes'
import { isSlttAppStorageEnabled, storeRemoteDocs, storeLocalDocs, retrieveRemoteDocs, retrieveLocalClientDocs, getStoredLocalClientIds, saveLocalSpots, saveRemoteDocsSpots, getRemoteSpots, getLocalSpots, getClientId, registerClientUser, SlttAppStorageDisabledError, getAuthorizedStorageProjects, getHasElectronContext } from './SlttAppStorage'
import { doOnceWhenBackOnline, getIsAppOnlineOrDefault, getIsAppOnlineOrWait, overridableUpdateOnlineStatus } from '../components/utils/ServiceStatus'
import { isHavingConnectionIssues, isNeedingLogout } from './API'
import { normalizeUsername } from './DBAcceptor.utils'
import { logDocSyncError } from '../components/utils/Errors'
import { delay } from '../components/utils/AsyncAwait'
import { generate4DigitPaddedNumber } from './utils/hashUtils'
import { LocalDoc, LocalSpot } from './lanStorage/docs'
import { tinyHash } from '../components/utils/tinyHash'
import { iterateAndDo } from './utils/dbServices'

const intest = (localStorage.getItem('intest') === 'true')
export const acceptLocalThruKeyString = localStorage.acceptLocalThruKey
const acceptLocalThruKey = Number.parseInt(acceptLocalThruKeyString || '')

if (acceptLocalThruKeyString) {
    if (Number.isNaN(acceptLocalThruKey)) {
        throw Error(`acceptLocalThruKey must be a number: ${acceptLocalThruKeyString}`)
    }
}
log(`intest ${intest} acceptLocalThruKey ${acceptLocalThruKeyString}`)

const maxSeq = 9999999999
const expectedLocalBrowserKeyLength = 38

export const MAXTOSYNC = 10

// At the moment the backend Express server can only accept 100K bytes.
// We need to fix that.

const MAXTOSYNCSIZE = 80000

function isRemoteSeqKey(key: unknown) {
    return typeof key === 'number' && key > 0 && key < minLocalSeqOld
}

function isOldLocalBrowserKey(key: unknown) {
    return (typeof key === 'number' && key >= minLocalSeqOld)
}

export function isNewLocalBrowserKey(key: unknown) {
    return (typeof key === 'string' && !isNaN(Number(key)) && expectedLocalBrowserKeyLength === key.length)
}

export function isLocalBrowserKey(key: unknown) {
    return (
        isOldLocalBrowserKey(key) ||
        isNewLocalBrowserKey(key)
    )
}

export function isLANKey(key: unknown) {
    return typeof key === 'string' && isNaN(Number(key))
}

interface IAccept {
    label?: string,
    seq?: number | string
}

export type dbPutWithOnlyAcceptParam = {
    put: (doc: IDBModDoc, onlyAccept: boolean) => void
}

export class _LevelupDB implements IDB {
    public db: IDBOperations
    acceptor?: IDBAcceptor
    changeDeferrer: DBChangeDeferrer
    notifier?: DBChangeNotifier
    api: IDBSyncApi
    dbId =  generate4DigitPaddedNumber()

    static forceSyncExceptionCount = 0

    // All documents in the LevelUp DB have a numeric keys.
    // Key values 0..minLocalSeqOld represent documents that exist in DynamoDB.
    //
    // Key values (old, number) localSeqBase+1..localSeqLast or (new, string) '20...' '21'... represent documents that have been created
    // locally but not yet synced to DynamoDB.
    // These documents are deleted once they have been synced to DynamoDB.
    // Sync will return to us from DynamoDB a permanent copy of each of these documents
    // to store locally [provided conflict resolution does not cause a specific
    // update to be ignored]


    // Largest remote key that has been received from sync API (remote keys start at 1)
    remoteSeq = 0

    numPendingSyncs = 0

    // Don't do an upsync when this is > 0.
    // When Date.now() is larger than this, reset this to 0.
    retryUpsyncTime = 0

    upsyncCount = 0 // number of times upsync attempted
    downsyncCount = 0 // number of times downsync attempted
    upsyncDocCount = 0
    downsyncDocCount = 0
    deletedCount = 0

    delayBeforeSyncRetry = 5 * 60 * 1000 // 5 minutes

    systemError: (error: any) => void

    constructor(public name: string, public username: string, db: IDBOperations, api: IDBSyncApi) {
        dbg(`constructor ${name}`)
        this.db = db
        this.name = name
        this.changeDeferrer = new DBChangeDeferrer(this)
        this.doSync = this.doSync.bind(this)
        this.api = api
        console.log(`dbId [${this.dbId}  ${this.name}]`)

        this.systemError = (error: any) => { console.error(`### [${this.dbId} ${name}] System Error`, error) }
    }

    getMaxToSyncSize() {
        return MAXTOSYNCSIZE
    }

    getRemoteSeq() {
        return this.remoteSeq
    }

    /**
     * @param seqStart (exclusive)
     * @param seqEnd (inclusive)
     * @returns IDBObject[seq] where seqStart < seq <= seqEnd
     */
    async get(seqStart: number | string, seqEnd: number | string) {
        return await this.db.get(seqStart, seqEnd)
    }

    async initialize(acceptor: IDBAcceptor, progress: (message: string) => void): Promise<number> {
        this.acceptor = acceptor
        let benchMarkStart = Date.now()
        let dbRecordCount = await this.acceptLocalDBRecords(progress)
        await this.doSync(progress)
        doOnceWhenBackOnline(
            'setupDbChangeNotifierWebsocket',
            this.setupDbChangeNotifierWebsocket
        )
        this.setupDoSyncOffline()
        let benchMarkEnd = Date.now()
        log(`_LevelupDB.initialize() finished in ${`${((benchMarkEnd - benchMarkStart)/1000).toFixed(2)}`}s`)
        return dbRecordCount
    }

    setupDbChangeNotifierWebsocket = () => {
        log(`_LevelupDB.setupDbChangeNotifierWebsocket()`, this.notifier ?? 'no notifier')

        // If, somehow, there was a previous notifier, disconnect it.
        this.notifier?.disconnect()

        if (!intest) {
            let onChange = async (maxseq: number) => {
                if (maxseq > this.remoteSeq) {
                    dbg('onChange', fmt({maxseq, remoteSeq: this.remoteSeq}))
                    await this.doSync()
                }
            }
            this.notifier = new DBChangeNotifier(this.name, onChange)
            this.notifier.requestNotifications()
        }
    }

    disconnect = () => {
        log(`_LevelupDB.disconnect()`, this.notifier ?? 'no notifier')
        this.notifier?.disconnect()
        this.notifier = undefined
        this.clearDoSyncOffline()
    }

    intervalDoSyncOffline: NodeJS.Timeout | undefined

    /**
     * Whenever offline doSync() every 5 seconds until back online.
     * 
     * History: setupDbChangeNotifierWebsocket() is not called during initialization while offline,
     * even if it were called, while offline it only calls doSync() every 2.5 minutes which 
     * is not frequent enough to handle multiple tabs/windows offline sync or local (LAN) team storage syncing.
     * 
     * So this interval timer was added to help provide more frequent offline syncing.
     */
    setupDoSyncOffline = () => {
        this.clearDoSyncOffline()
        console.log(`intervalDoSyncOffline - setup - dbId: ${this.dbId} name: ${this.name}`)
        this.intervalDoSyncOffline = setInterval(() => {
            // setup 5 second timer to doSync() when offline (for possible local team storage sync or 
            // multiple tab/windows sync
            if (getIsAppOnlineOrDefault(false) === false) {
                console.log(`intervalDoSyncOffline - doSync() - dbId: ${this.dbId} name: ${this.name}`)
                this.doSync()
            }
        }, 5000)
    }

    clearDoSyncOffline = () => {
        if (this.intervalDoSyncOffline) {
            clearInterval(this.intervalDoSyncOffline)
            this.intervalDoSyncOffline = undefined
        }
    }

    /**
     * @returns db entries keys in this order: isRemoteSeqKey, isLANKey, isLocalBrowserKey
     * NOTE: once users no longer have old local browser keys, we may be able to get rid of this sorting,
     * because the current db key schemes should follow the order of remoteSeq, LAN, localBrowser
     */
    async getEntriesSortedForAccept() {
        const entries = await this.db.readDBRecords()
        const remoteSeqEntries = []
        const lanEntries = []
        const localBrowserEntries = []

        for (const entry of entries) {
            if (isRemoteSeqKey(entry.key)) {
                remoteSeqEntries.push(entry)
            } else if (isLANKey(entry.key)) {
                lanEntries.push(entry)
            } else if (isLocalBrowserKey(entry.key)) {
                localBrowserEntries.push(entry)
            }
        }

        return [
            ...remoteSeqEntries,
            ...lanEntries,
            ...localBrowserEntries,
        ]
    }

    /**
     * `startingKeyForOtherDbIdQuery` is first set in `acceptLocalDBRecords` 
     * (as the last local key found) then subsequently updated in the 
     * context of doSync() (acceptLocalChangesFromOtherBrowserWindowTabs)
     * whenever this (dbId) instance of _LevelUpDB discovers local browser keys stored 
     * in the db from other tabs (i.e. with other dbIds) that it hasn't accepted
     * Initially this will be the last local browser key that was accepted in `acceptLocalDBRecords`
     * NOTE: Whenever acceptLocalDBRecords() is called dbId will have been regenerated since
     * the last time a browser/tab stored the record.
     * NOTE: THEORETICALLY it's possible for another _LevelupDB instance (with online status) 
     * to also update remoteSeq without the other (offline) tabs also updating remoteSeq, 
     * and so not all remoteSeqs updates will have been accepted across all such offline tabs. 
     * For now we'll assume this is not a situation we need to support since in general
     * all tabs should have the same online/offline status.
     * We can however, warn or report if we ever discover remoteSeq getting out of sync
     */
    startingKeyForOtherDbIdQuery = MIN_LOCAL_SEQ

    /** Called once on initialize to accept all records in DB.
    * Side effect: sets `remoteSeq` and `startingKeyForOtherDbIdQuery`
    */
    async acceptLocalDBRecords(progress: (message: string) => void): Promise<number> {
        let notifyLocalProgress = (percent: number) => {
            progress(t`Initializing ...` + `${percent.toFixed(1)}%`)
        }
        let percent = 0
        notifyLocalProgress(percent)
        let entries = await this.getEntriesSortedForAccept()

        for (let i = 0; i < entries.length; ++i) {
            let entry = entries[i]
            if ((typeof entry.key === 'number' && entry.key <= 0) || !entry.doc || !entry.doc._id) {
                log('###BAD ENTRY', entry)
            }

            let { key } = entry

            if (isLocalBrowserKey(key)) {
                if (isOldLocalBrowserKey(key)) {
                    // TODO: report/beacon to rollbar after a certain date
                    console.warn(`###acceptLocalDBRecords: old local browser key ${key}`)
                } else {
                    this.startingKeyForOtherDbIdQuery = String(key)
                }
                const { modEntry } = await this.tryMigration(entry)
                if (modEntry) {
                    entry = modEntry
                }
            } else if (isRemoteSeqKey(key)) {
                this.remoteSeq = key as number
            }

            if (i % 500 === 0) {
                let percent = 100 * i / entries.length
                await delay(1) // let ui redraw to show progress
                notifyLocalProgress(percent)
            }

            if (acceptLocalThruKey && typeof entry.key === 'number' && (entry.key > acceptLocalThruKey)) {
                if (entry.key === acceptLocalThruKey) {
                    log('acceptLocalDBRecords acceptLocalThruKey', { acceptLocalThruKey, acceptLocalThruKeyString, key, entry, })
                }
                continue // to allow remoteSeq to reflect latest remoteSeq
            }
            this.accept(entry.doc, {seq: key})
        }
        console.log(`acceptLocalDBRecords [${this.dbId}  ${this.name}]`)

        return entries.length
    }

    accept(doc: IDBModDoc, arg?: IAccept) {
        let label = arg?.label ?? ''

        if (label) {
            dbg(`accept[${label}]`, doc._id)
        }
        let seq: number | string = arg?.seq ?? -1
        try {
            this.acceptor?.accept(doc, label, seq)
        } catch (error) {
            //!!! send .errors to remote log
            console.error(`_LevelupDB [${this.name}] accept seq ${seq} label '${label}' doc ${s(doc)}`, error)
        }
    }

    /**
     * try to discover any db records from other browser tab/windows (dbIds)
     * that have not yet been accepted since acceptLocalDBRecords()
     * 1. iterate starting from where we left off in acceptLocalDBRecords (or here),
     * find remote or local browser records from other dbIds
     * that we have not yet accepted
     * 2. accept those docs
     * 3. update `remoteSeq` or `startingKeyForOtherDbIdQuery`
     * 
     * NOTE side-effects: updates `remoteSeq` and `startingKeyForOtherDbIdQuery`
     */
    async acceptLocalChangesFromOtherBrowserWindowTabs() {
        if (getHasElectronContext()) {
            // no plans (yet) to support multiple windows
            // sharing the same browser session/database
            return
        }
        // 1. first find and accept remote records from other dbIds
        // NOTE: we typically expect DBChangeNotifier to keep us up to date while online
        // but it's possible there's an edge case during intermittent connection
        await iterateAndDo(this.db, this.remoteSeq,
            /* shouldStop */
            (item) => !isRemoteSeqKey(item.key),
            /* doEach */
            async (item) => {
                const seq = Number(item.key)
                if (seq > this.remoteSeq && item.value) {
                    console.debug(`### acceptLocalChangesFromOtherBrowserWindowTabs: new remote key ${seq}`)
                    this.accept(item.value, { seq })
                    this.remoteSeq = seq
                }
            }
        )
        // 2. find and accept local browser records from other dbIds
        await iterateAndDo(this.db, this.startingKeyForOtherDbIdQuery,
            /* shouldStop */
            (item) => !isNewLocalBrowserKey(item.key),
            /* doEach */
            async (item) => {
                const seq = String(item.key)
                const dbId = extractDbIdFromSeq(seq) // extract dbId from key
                if (this.dbId !== dbId && seq > this.startingKeyForOtherDbIdQuery && item.value) {
                    this.accept(item.value, { seq })
                    this.startingKeyForOtherDbIdQuery = seq
                }
            }
        )
    }

    // Synchronize items in local DB with central  DB
    async doSync(progress?: (message: string) => void) {
        if (!this.api.loggedIn()) return

        // If we run multiple simultaneous sync we will get duplicate db records.
        // Just wait until we are done with this sync.
        if (this.numPendingSyncs > 0) {
            return
        }

        this.numPendingSyncs++
        const syncingMessage = t`Syncing`
        progress?.(syncingMessage)

        try {
            await this.acceptLocalChangesFromOtherBrowserWindowTabs()
            if (isSlttAppStorageEnabled()) {
                await this.syncLANStorageDocs(progress)
            }
            if (await getIsAppOnlineOrWait()) {
                // if there's an internet connection, then do apiSync
                progress?.(syncingMessage)
                await this.syncDocs()
            } else {
                // when internet comes back, try doSync() again
                doOnceWhenBackOnline('doSync', this.doSync)
            }
        } catch (error) {
            if (isHavingConnectionIssues(error)) {
                // set offline status and when internet comes back, try doSync() again
                await overridableUpdateOnlineStatus(false)
                doOnceWhenBackOnline('doSync', this.doSync)
            } else if (isNeedingLogout(error) || !this.api.loggedIn()) {
                this.systemError(error)
                // allow user to be logged out
            } else {
                this.systemError(error)
                // report to rollbar, if we haven't already done so
                if (isNeedingReporting(error) && getIsAppOnlineOrDefault(true)) {
                    let docs: IDBModDoc[] = []
                    try {
                        const { itemsToUpsync } = await this.getDocsSlice()
                        docs = itemsToUpsync.map(item => item.doc)
                        // TODO: try again when back online?
                        logDocSyncError(error as Error, docs, this.name, this.dbId, false)
                    } catch (e) {
                        this.systemError(e)
                    }
                }
                this.setupRetryTimeout()
            }
        } finally {
            this.acceptor?.fixProjectPlanIds()
            this.numPendingSyncs--
        }
        if (isSlttAppStorageEnabled()) {
            const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
            if (authorizedStorageProjects.has(this.name)) {
                // if there are unsynced local browser docs, then backup to LAN local storage
                await this.storeLocalBrowserDocsToLANStorage(progress)
            }
        }
    }

    async syncLANStorageDocs(progress: ((message: string) => void) | undefined) {
        let allEntries: DBEntry[] = []

        const getAllEntries = async (): Promise<DBEntry[]> => {
            if (allEntries.length === 0) {
                allEntries = await this.db.readDBRecords()
            }
            return allEntries
        }

        try {
            const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
            if (!authorizedStorageProjects.has(this.name)) {
                return
            }
            const originalDbCount = await this.db.count() // ie. was database empty before sync?
            await registerClientUser({ username: this.username }, '_LevelupDB.doSync(): LAN storage')
            await this.syncRemoteLANStorageDocs(getAllEntries, progress)
            await this.syncLocalLANStorageDocs(getAllEntries, progress, originalDbCount === 0)
        } catch (error) {
            this.systemError(error)
            if (error instanceof SlttAppStorageDisabledError) {
                // if getHasLostConnection() fails then SlttAppStorage will become disabled,
                // so avoid calling other storage api functions
                return
            }
        }
    }

    private async syncRemoteLANStorageDocs(
        getAllEntries: () => Promise<DBEntry[]>,
        progress: ((message: string) => void) | undefined,
    ) {
        const logContextRemoteDocs = '_LevelupDB.doSync(): LAN remote docs'
        progress?.(t`Syncing LAN remote docs ...`)
        let remoteSpots = await getRemoteSpots({ project: this.name }, logContextRemoteDocs)

        log(logContextRemoteDocs)
        const lastSpotSeqOrig = remoteSpots!['last']?.seq ?? 0
        if (this.remoteSeq === 0 && lastSpotSeqOrig > 0) {
            // special case when user has deleted the database: reset our spots
            remoteSpots = {}
        }
        const spot = remoteSpots!['last']
        const remoteDocsResponse = await retrieveRemoteDocs(
            { project: this.name, spot },
            logContextRemoteDocs
        )
        // save spot
        if (remoteDocsResponse) {
            await saveRemoteDocsSpots(
                {
                    project: this.name, spots: { 'last': remoteDocsResponse.spot }
                }, logContextRemoteDocs)
        }
        const lastLANRemoteSeq = remoteDocsResponse?.seqDocs.slice(-1)[0]?.seq ?? 0
        if (lastLANRemoteSeq > this.remoteSeq) {
            const newRemoteSeqDocs = remoteDocsResponse?.seqDocs
                .filter((item) => item.seq > this.remoteSeq) ?? []
            const newRemoteItems = newRemoteSeqDocs.map((seqDoc) => ({ project: this.name, doc: seqDoc.doc, seq: seqDoc.seq }))
            await this.acceptRemoteItems(newRemoteItems, true)
        } else {
            await this.storeRemoteBrowserDocsToLANStorage(
                getAllEntries,
                lastLANRemoteSeq,
                progress
            )
        }
    }

    private async syncLocalLANStorageDocs(
        getAllEntries: () => Promise<DBEntry[]>,
        progress: ((message: string) => void) | undefined,
        resetSpots = false
    ) {
        progress?.(t`Syncing LAN local docs ...`)
        const logContextLocalDocs = '_LevelupDB.doSync(): LAN local docs'
        log(logContextLocalDocs)
        const clientIds = await getStoredLocalClientIds({ project: this.name }, logContextLocalDocs)
        if (clientIds.length > 0) {
            const allEntries = await getAllEntries()
            const latestCachedUpdatesById = new Map(allEntries.map(
                entry => [
                    entry.doc._id,
                    {
                        seq: entry.key,
                        modDate: entry.doc.modDate
                    }
                ]))
            const localDocs: LocalDoc<IDBModDoc>[] = []
            let savedSpots = await getLocalSpots({ project: this.name }, logContextLocalDocs)
            if (resetSpots) {
                // special case when user has deleted the database: reset our spots
                savedSpots = {}
            }
            const spots: LocalSpot[] = []
            for (let i = 0; i < clientIds.length ; i++) {
                // TODO: calculate progress
                const clientId = clientIds[i]
                const clientSpot = (savedSpots['last'] || []).find(spot => spot.clientId === clientId)
                // NOTE: winningIncomingLocalClientDocs should exclude even our own already stored
                const localClientDocsResponse = await retrieveLocalClientDocs(
                    { localClientId: clientId, project: this.name, spot: clientSpot },
                    '_LevelupDB.doSync(): LAN local docs'
                )
                if (!localClientDocsResponse) continue
                const { localDocs: incomingLocalClientDocs, spot } = localClientDocsResponse
                const winningIncomingLocalClientDocs = incomingLocalClientDocs.filter(localDoc => {
                    const cachedEntry = latestCachedUpdatesById.get(localDoc.doc._id)
                    if (cachedEntry && cachedEntry.modDate >= localDoc.doc.modDate) {
                        log(`_LevelupDB.doSync() incoming local doc not later than cached mod doc (${fmt(cachedEntry)})`, fmt(localDoc))
                        return false
                    }
                    return true
                })
                localDocs.push(...winningIncomingLocalClientDocs)
                spots.push(spot)
            }
            // save spots
            await saveLocalSpots(
                { project: this.name, spots: { 'last': spots } },
                logContextLocalDocs
            )
            const sortedIncomingLocalDocs = sortBy(localDocs, localDoc => localDoc.doc.modDate)
            const newLocalItems = sortedIncomingLocalDocs.map(localDoc => (
                { project: this.name, doc: localDoc.doc, seq: transformDocToLANKey(localDoc.clientId, localDoc.doc) }))
            
            if (newLocalItems.length > 0) {
                await this.db.writeItems(newLocalItems)
            }
            newLocalItems.forEach(item => this.accept(item.doc, { seq: item.seq }))
        } else {
            this.storeLANBrowserDocsToLANStorage(
                getAllEntries,
                progress
            )
        }
    }

    /**
     * LAN storage can lack browser cached remote docs, e.g.
     * if browser local docs got migrated to LAN storage (while offline)
       if the client then got disconnected from LAN storage but synced with remote server (back online)
       then, the new remote docs were not stored in LAN storage, so we need to do so now.
     * @param seqDocs
     * @param lastLANRemoteSeq 
     * @param progress 
     * @returns 
     */
    async storeRemoteBrowserDocsToLANStorage(
        fnGetAllEntries: () => Promise<DBEntry[]>,
        lastLANRemoteSeq: number,
        progress: ((message: string) => void) | undefined) {
        
        const newSeqDocs = (await fnGetAllEntries())
            .filter(entry => isRemoteSeqKey(entry.key) && Number(entry.key) > lastLANRemoteSeq && entry.doc)
            .map(entry => ({ doc: entry.doc, seq: Number(entry.key) }))
        if (newSeqDocs.length > 0) {
            await storeRemoteDocs({ project: this.name, seqDocs: newSeqDocs }, '_LevelupDB.storeRemoteBrowserDocsToLANStorage()')
        }
    }

    /**
     * LAN storage can lack browser LAN docs, e.g. 
     * if they were deleted from the LAN storage
        or client connected to a different LAN storage device
     * @param allLocalLANfilenames
     * @param progress 
     */
    async storeLANBrowserDocsToLANStorage(
        fnGetAllEntries: () => Promise<DBEntry[]>,
        progress: ((message: string) => void) | undefined
    ) {
        // we possibly have localLAN keys but no clientIds. This indicates the LAN storage has changed
        // (either the client has connected to a different LAN storage device or the LAN storage has been cleared)
        // store browser cached LAN docs to the fresh LAN storage
        // Restore all local LAN data missing from LAN storage, even for other clients,
        // but treat them as if they were from our client in {our-client}.sltt-docs 
        // (this avoids a race-condition if multiple clients try to re-write {each-client}.sltt-docs) 
        // to restore this data)
        // The downside of storing all data under our clientId, 
        // is that the client data stored on disk will no longer match what we have stored in the browser cache.
        // when other clients connect to the LAN storage, they will try to fetch what appears to be data from us
        // but hopefully the logic that tries to avoid loading duplicate or outdated data will prevent any issues
        // Also the modBy will be out of sync with previously registered users for that client, 
        // but hopefully that's not the end of the world, since we store that info separately (although those users will 
        // need to be re-registered for us to know for sure).
        const newLocalDocs = (await fnGetAllEntries())
            .filter(entry => isLANKey(entry.key))
            .map(entry => entry.doc)
        if (newLocalDocs.length === 0) return
        await storeLocalDocs({ project: this.name, docs: newLocalDocs }, '_LevelupDB.storeLANBrowserDocsToLANStorage()')
    }

    async storeLocalBrowserDocsToLANStorage(progress: ((message: string) => void) | undefined) {
        progress?.(t`Storing browser local docs to LAN local storage ... `)
        const docs = [
            ...await this.db.get(minLocalSeqOld, maxLocalSeqOld),
            ...await this.db.get(MIN_LOCAL_SEQ, MAX_LOCAL_SEQ),
        ]
        const localBrowserSeqsToDelete = (await this.db.readKeys()).filter(isLocalBrowserKey)
        // TODO: should we avoid storing docs for _ids that are already in LAN storage with a later modDate?
        // or is it okay to back them up before deleting them?
        await storeLocalDocs({ project: this.name, docs }, '_LevelupDB.storeLocalBrowserDocsToLANStorage()')
        // TODO: should we assume success means they were stored? Or should we retrieve them to confirm?
        // if we retrieve them, we should save our spot so we can ignore them in the future
        const migratedLANLocalItems = docs.map(doc => ({ project: this.name, seq: transformDocToLANKey(getClientId(), doc), doc }))
        await this.db.writeItems(migratedLANLocalItems)
        await this.deleteLocalDocs(localBrowserSeqsToDelete)
    }

    // Sync docs with remote server.
    // Do upsync in modest size chunks to stay within server request size limit.
    // Repeat down sync if we got a lot of records, because there may be more to get.
    async syncDocs() {
        let prevItemsToUpsync: IDBItem[] | undefined = undefined
        while (true) {
            dbg(`syncDocs`, fmt({ retryUpsyncTime: this.retryUpsyncTime }))

            if (!this.api.loggedIn()) break // no use trying to sync if not logged in

            const dateNow = Date.now()
            if (this.retryUpsyncTime <= dateNow) {
                dbg('syncDocs retry upsync')
                this.retryUpsyncTime = 0
            }

            if (this.retryUpsyncTime === 0) { // no nonretirable error in progress
                ++this.upsyncCount
                const { itemsToUpsync, itemsWithBadOrBigDocs, didBreakDueToPayloadSize } = await this.getDocsSlice()
                const lastSeqInSlice = itemsToUpsync.slice(-1)[0]?.seq ?? -1
                if ((lastSeqInSlice === prevItemsToUpsync?.slice(-1)[0]?.seq)) {
                     console.error(`syncDocs: lastSeqInSlice (${lastSeqInSlice}) did not advance. Stuck?`)
                     break
                } else {
                    prevItemsToUpsync = [...itemsToUpsync]
                }
                // If requested from console, force N sync errors.
                // In console: window._LevelupDB.forceSyncExceptionCount = 1000
                if (_LevelupDB.forceSyncExceptionCount > 0) { 
                    --_LevelupDB.forceSyncExceptionCount
                    log('syncDocs test error handling')
                    throw Error(TEST_ERROR_HANDLING_MESSAGE)
                }

                if (this.api.blockServerUpdates() && itemsToUpsync.length > 0) {
                    const lastDbItem = itemsToUpsync.slice(-1)[0]
                    log(`### Aborting sync: API.blockServerUpdates() is true. There are ${itemsToUpsync.length} doc(s) in ${this.fullDbName}, e.g. [${lastDbItem.seq}: ${lastDbItem.doc._id}]`)
                    return
                }
                const { error, newItems } = await this.apiSync(itemsToUpsync) // This will set retryUpsyncTime if there is a nonretriable upsync error.
                if (!error) {
                    await this.acceptRemoteItems(newItems)
                    await this.deleteLocalDocs(itemsToUpsync.map(({ seq }) => seq))
                    // REVIEW: should we delete local docs with bad or big docs?
                    // historically that's what we've done, but we could instead use
                    // _LevelupDB.tryMigration() to fix the docs and then upsync them again.
                    // For now, they should have been reported to rollbar (if still online),
                    // so we could possibly recover them from there if needed. 
                    await this.deleteLocalDocs(itemsWithBadOrBigDocs.map(({ seq }) => seq))

                    if (newItems.length > 100) { continue /* repeat sync loop, likely more downsync to do  */ }
                    if (itemsToUpsync.length >= MAXTOSYNC) { continue /* repeat sync loop, likely more upsync to do */ }
                    if (didBreakDueToPayloadSize) { continue /* repeat sync loop, likely more upsync to do */ }
                }
            }

            if (this.retryUpsyncTime > 0) { // nonretriable error in progress
                ++this.downsyncCount

                const { error, newItems } = await this.apiSync([]) // do downsyc only

                if (!error) {
                    await this.acceptRemoteItems(newItems)
                }
                if (newItems.length > 100) { continue /* repeat sync loop, likely more downsync to do */ }
            }

            break // use 'continue' for conditions when don't want to break out of the loop
        }
    }

    async tryMigration(originalEntry: DBEntry): Promise<{ modEntry: DBEntry | undefined }> {
        if (!isLocalBrowserKey(originalEntry.key)) { return { modEntry: undefined } }
        // create modEntry only if we need to modify the entry (preserve performance)
        // clone it from originalEntry to avoid modifying the original entry
        let modEntry: DBEntry | undefined = undefined
        const getModEntry = () => {
            if (!modEntry) {
                modEntry = JSON.parse(JSON.stringify(originalEntry))
            }
            return modEntry!
        }
        
        if (!originalEntry.doc.modBy) {
            // all new upsynced docs are expected to have a modBy field
            const modBy = normalizeUsername(this.username) // WARNING: if new user logged in, this user could potentially
            const modEntry = getModEntry()                 // be a different user, if an old DBChangeNotifier is using an old _LevelupDB.
            modEntry.doc.modBy = modBy                     // see https://github.com/ubsicap/sltt/issues/916
            console.log(`tryMigration: added modBy '${modBy}' to entry (${modEntry.key}, ${modEntry.doc._id})`)
        }
        if (originalEntry.doc._id === 'project' && 'projectBookName' in originalEntry.doc) {
            // cause of `/sync ERROR: Error: "members" field missing`
            // see https://app.rollbar.com/a/biblesocieties/fix/item/SLTT/248
            /* {
                    "_id": "project", // --> "teamPreferences" 
                    "creator": "caio.cascaes@gmail.com",
                    "creationDate": "2024/02/26 18:33:32.399Z",
                    "modDate": "2024/02/26 18:37:48.026Z",
                    "bbbccc": "001",
                    "projectBookName": "1 Mosebog"
                }, 
            */
            const modEntry = getModEntry();
            (modEntry.doc as any)._id = 'teamPreferences'
            console.log(`tryMigration: changed _id from 'project' to 'teamPreferences' in entry (${modEntry.key}) doc: ${s(modEntry.doc)}`)
        }
        if (!!modEntry) {
            const modEntry = getModEntry()
            await this.db.put(modEntry.key, modEntry.doc)
        }
        return { modEntry }
    }

    setupRetryTimeout() {
        if (this.retryUpsyncTime > 0) { return } // avoid multiple timers
        if (!this.api.loggedIn()) { return } // no use trying to sync if not logged in
        if (this.api.blockServerUpdates()) { return } // no use trying to sync if blockServerUpdates is true
        if (!getIsAppOnlineOrDefault(false)) { return } // no use trying to sync until online
        // Record the fact that future upsyncs are likely to fail.
        this.retryUpsyncTime = Date.now() + this.delayBeforeSyncRetry
        console.log(`setupRetryTimeout: retryUpsyncTime set to ${new Date(this.retryUpsyncTime).toISOString()}`)

        // In 5 minutes retry the sync in case something has happened to resolve the error.
        // We wait just a little longer than the delayBeforeRetry to ensure that the
        // time check in the main loop realizes we are past the delayBeforeRetry time.
        setTimeout(() => { 
            this.doSync().catch(this.systemError) 
        }, 1.1*this.delayBeforeSyncRetry)
    }

    async apiSync(itemsToUpsync: IDBItem[]) {
        try {
            const itemsFromOtherLevelUpId = itemsToUpsync.filter((item => isNewLocalBrowserKey(item.seq) && extractDbIdFromSeq(String(item.seq)) !== this.dbId ))
            if (itemsFromOtherLevelUpId.length > 0) {
                console.log(`apiSync: ${itemsFromOtherLevelUpId.length} items from other dbId`, itemsFromOtherLevelUpId)
            }
            const docs = itemsToUpsync.map(({ doc }) => doc)
            const newItems = await this.api.sync(this.name, docs, this.remoteSeq, this.dbId)
            this.downsyncDocCount += newItems.length
            this.upsyncDocCount += docs.length

            return { error: null, newItems }
        } catch (_error) {
            if (isHavingConnectionIssues(_error) ||
                isNeedingLogout(_error) || 
                isNeedingReporting(_error)) {
                // offline...no point in retrying until back online
                // or user needs to login before syncing again
                // or error still needs to be reported before setupRetryTimeout() is called
                throw _error
            }
            const error = _error as Error
            this.setupRetryTimeout()
            return { error, newItems: [] }
        }
    }

    async deleteLocalDocs(seqsToDelete: (number|string)[]) {
        for (const seq of seqsToDelete) {
            dbg(`deleteLocalDocs[${seq}]`)
            await this.db.deleteDoc(seq)
            this.deletedCount++
        }
        dbg('deleteLocalDocs done', fmt({ deletedCount: this.deletedCount, seqsToDelete }))
    }

    acceptRemoteItems = async (items: IRemoteDBItem[], skipWriteToDisk: boolean = false) => {
        if (items.length === 0) return

        let lastSeq = items.slice(-1)[0].seq
        dbg(`acceptRemoteItems`, fmt({ lastSeq, items: items.length, remoteSeq: this.remoteSeq }))
        dbg(`acceptRemoteItems`, items)

        await this.db.writeItems(items)
        this.remoteSeq = lastSeq

        items.forEach(item => this.accept(item.doc, {seq: item.seq}))
        if (isSlttAppStorageEnabled()) {
            const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
            if (!authorizedStorageProjects.has(this.name)) {
                return
            }
            !skipWriteToDisk && await storeRemoteDocs({ project: this.name, seqDocs: items.map(item => ({ seq: item.seq, doc: item.doc })) }, '_LevelupDB.acceptRemoteItems()')
            const clientIds = await getStoredLocalClientIds({ project: this.name }, '_LevelupDB.acceptRemoteItems()')
            for (const item of items) {
                if (!item.doc.modBy) continue // remote doc before modBy was added (before LAN storage)
                // TODO: alternatively, we could get all our lan keys and figure out which clients apply
                for (let clientId of clientIds) {
                    const lanKey = transformDocToLANKey(clientId, item.doc)
                    await this.db.deleteDoc(lanKey) // delete any LAN local key for any client
                }
            }
        }
    }

    /**
     * It should not be possible to create a big doc, but if so truncate it so
     * that it does not crash the sync process.
     * @param doc: the doc to check. WARNING: this function modifies this doc if it's too big.
     */
    limitDocSize(doc: any, docs: IDBModDoc[]): number {
        const length = 2*JSON.stringify(doc).length // 2* because of utf-16
        if (length < this.getMaxToSyncSize()) return length
        
        const copyOfDoc = JSON.parse(JSON.stringify(doc))
        // This assumes that the size problem in the text or src fields.
        doc.text = ''
        doc.src = ''
        doc.error = "*TOO BIG*"

        const newLength = 2*JSON.stringify(doc).length

        logDocSyncError(
            new Error(
                `${NonretriableSyncFailure}: unexpected big doc (${length} >= ${MAXTOSYNCSIZE} bytes) in getDocsSlice()...truncating last doc to ${newLength} bytes`
            ),
            [...docs, { beforeTruncation: copyOfDoc, afterTruncation: doc }], this.name, this.dbId, false, false
        )
        return newLength
    }

    /**
     * Return a slice of (local) docs to (up)sync
     * Must not be more than MAXTOSYNC docs.
     * Must not be more than MAXTOSYNCSIZE bytes.
     * NOTE: it's possible that when running SLTT in multiple browser windows/tabs,
     * this (b/c of doSync() race condition) may process local db items that 
     * had been created in a different window/tab. See https://github.com/ubsicap/sltt/pull/1059
     */
    async getDocsSlice() {
        const dbItemsToUpsync: IDBItem[] = []
        const itemsWithBadOrBigDocs: IDBItem[] = []
        let totalLength = 0
        let didBreakDueToPayloadSize = false

        const getDocSizeOrError = (seq: number | string, doc: IDBModDoc | undefined, totalLength: number, docs: IDBModDoc[]): 
            { docSize: number, flowRedirection: 'continue' | 'break' | 'none' } =>
        {
            if (!doc || !doc._id) {
                log(`###BAD DOC`, fmt({ seq, doc }))
                logDocSyncError(
                    new Error(
                        `${NonretriableSyncFailure}: unexpected bad doc in getDocsSlice(): {seq: ${seq}, doc: ${JSON.stringify(doc)}}`
                    ),
                    docs, this.name, this.dbId, false, false
                )
                return { docSize: 0, flowRedirection: 'continue' }
            }
            const length = this.limitDocSize(doc, docs)
            const newTotalLength = totalLength + length
            if ((newTotalLength) > MAXTOSYNCSIZE) {
                if (length > MAXTOSYNCSIZE) {
                    // truncation failed to reduce size...we've already reported in limitDocSize() so just continue
                    this.systemError(new Error(`### getDocsSlice(): limitDocSize failed to truncate enough`))
                    return { docSize: length, flowRedirection: 'continue' }
                } else {
                    console.log(`getDocsSlice(): totalLength ${newTotalLength} > ${MAXTOSYNCSIZE} bytes`)
                    // the length of this doc will exceed the total limit, so break
                    return { docSize: length, flowRedirection: 'break' }
                }
            }
            return { docSize: length, flowRedirection: 'none' }
        }

        const processItem = async (nextItem: any) => {
            const doc = nextItem.value!
            const { docSize, flowRedirection } = getDocSizeOrError(nextItem.key, doc, totalLength, [...dbItemsToUpsync.map(item => item.doc)])
            if (flowRedirection === 'break') {
                // doSync() should be called again to continue processing a new slice
                didBreakDueToPayloadSize = true
                return
            }
            if (flowRedirection === 'continue') {
                itemsWithBadOrBigDocs.push({ project: this.name, seq: nextItem.key, doc: doc })
                return
            }
            totalLength += docSize
            dbItemsToUpsync.push({ project: this.name, seq: nextItem.key, doc: doc })
        }

        const shouldStop = () => (dbItemsToUpsync.length >= MAXTOSYNC || didBreakDueToPayloadSize)
        await iterateAndDo(this.db, MIN_LAN_SEQ,
            (item) => shouldStop() || !isLANKey(item.key),
            processItem
        )
        await iterateAndDo(this.db, minLocalSeqOld,
            (item) => shouldStop() || !isOldLocalBrowserKey(item.key),
            processItem
        )
        await iterateAndDo(this.db, MIN_LOCAL_SEQ,
            (item) => shouldStop() || !isNewLocalBrowserKey(item.key),
            processItem
        )

        return { itemsToUpsync: dbItemsToUpsync, itemsWithBadOrBigDocs, didBreakDueToPayloadSize }
    }

    put = async (doc: IDBModDoc, onlyAccept: boolean = false, skipWriteToDisk: boolean = false) => {
        if (onlyAccept || this.api.blockServerUpdates()) {
            this.accept(doc)
            return
        }

        const { db } = this
        const seq = generateLocalBrowserSeq(doc, this.dbId)
        console.log(`put [${this.dbId}  ${this.name}]: seq ${seq} _id ${doc._id}`)
        dbg('put', fmt({ seq }))
        await db.put(seq, doc)

        this.accept(doc, {label: `put ${seq}`, seq})

        this.doSync().catch(this.systemError) // do not wait for sync to finish
    }

    /**
     * Some operations, such as dragging a gloss, create a lot of potential changes to the db.
     * For these operations do not commit a change until we go 5 seconds without getting
     * a new value and then commit only the latest value.
     */
    submitChange(doc: IDBModDoc) {
        if (this.api.blockServerUpdates()) {
            this.accept(doc)
        } else {
            let seq = -1
            this.accept(doc, {label: `put ${seq}`, seq})
            this.changeDeferrer.submitChange(doc)
        }
    }

    static lastId = ''

    static dateToId(date: Date, tag: string = '') {
        // When running tests use a constant time stamp
        if (intest) {
            return tag + '190101_020304'
        }

        let _id = date.toISOString().slice(2, -5)
        _id = _id.replace('T', '_')
        _id = _id.replace(/\-/g, '')
        _id = _id.replace(/:/g, '')

        let lastId = _LevelupDB.lastId
        if (lastId >= _id) {
            let parts = lastId.split('_')
            let newTime = (parseInt(parts[1]) + 1).toString()
            _id = parts[0] + '_' + newTime.padStart(6, '0')
        }

        _LevelupDB.lastId = _id

        return tag + _id
    }

    /**
     * Get a final part for a new _id for a new entity that is unique among existing entities (within the browser window/tab's memory).
     * NOTE: In rapid bulk operations, this may result in a time that goes beyond normal hhmmss values
     * As a result, parsing this into time may not work as expected.
     * So, if Date.now() results in max time `235959` (11:59:59pm) it leaves room for 764,040 more batch items (max 999999)
     * 
     * WARNING: if a user is doing the same bulk operation simultaneously in multiple browser windows/tabs
     * it will likely produce duplicate ids, unfortunately. But we don't expect normal users to do that (testers maybe).
     * @param existing list of entities to check for uniqueness (e.g. passages)
     * @param date (typically Date.now()) 
     * @param tag (e.g. `plan_`)
     * @returns the last part of the _id for the new entity 
     */
    getNewId(existing: IDBObject[], date: Date, tag: string = '') {
        // Get the last part of the _id for each existing object
        const oldIds = existing.map((dbObj: any) => dbObj._id?.split('/').slice(-1)[0])
        let newId = _LevelupDB.dateToId(date, tag)

        // It is possible, especially with batch operations, that the newId is already in use.
        // Keep incrementing the hhmmss at end of _id until we get a unique _id.
        // Some unit test cases cause this to happen all the time by freezing the date with a mock.
        while (oldIds.includes(newId)) {
            const parts = newId.split('_')
            const i = parts.length - 1 // index of hhmmss number
            parts[i] = (parseInt(parts[i]) + 1).toString().padStart(6, '0')
            const _newId = parts.join('_')

            if (newId === _newId) {
                throw new Error('getNewId failed') // should never happen, but if so, break out of loop
            }
            newId = _newId
        }

        return newId
    }
    
    async delete(doc: any /* really IDBObject */) {
        doc.removed = true
        await this.put(doc)
    }

    // async getOne(_id: string) {
    //     return this.db.get(_id)
    // }
    
    getDate() {
        if (intest) {
            return '2019/09/10 11:12Z'
        }

        return _LevelupDB.getDate()

    }

    /**
     * last date in epoch milliseconds returned from getDate()
     * NOTE: this may be a near future date to provide uniqueness within the same browser window/tab
     * 
     * WARNING: This does not guarantee uniqueness across multiple browser/tabs.
     */
    static lastDateMs = 0
    /**
     * Returns a UNIQUE (possibly near future) date with FORMAT 2020/10/03 19:01:14.093Z
     * 1) WARNING: we use this date FORMAT to determine in the back end what change is the latest.
     * Changing the format of this will likely cause data loss due to items being judged outdated
     * and being discarded.
     * 2) Guaranteeing uniqueness is for use as a key in a database.
     * NOTE: in the typical use case, calls to getDate() will naturally be unique since 
     * because they get called in context of users interacting with the app to create DBObjects.
     * However, there are some cases where multiple DBObjects are created in a batch 
     * (e.g. Project.addDefaultProjectPlan(), or when importing a project). In those cases,
     * DBObjects can be created so rapidly that they get the same date.
     * This can cause problems when the date is used as a key in a database, especially
     * when those keys are used for objects with different _id's.
     * Review: If uniqueness causes performance issues for large batches,
     * we may need to change this.
     * 
     * WARNING: This does not guarantee uniqueness across multiple instances of the app.
     * Hopefully the user will not be doing parallel batch operations across multiple instances of the app
     * */ 
    static getDate() {
        const beginMs = new Date().getTime() // alternative to Date.now() to avoid mocks
        let updatedMs = beginMs
        if (updatedMs > _LevelupDB.lastDateMs) {
            _LevelupDB.lastDateMs = beginMs
        } else {
            updatedMs = _LevelupDB.lastDateMs + 1
            _LevelupDB.lastDateMs = updatedMs
            // for every second that lastDateMs is beyond beginMs, warn in the console
            if ((updatedMs - beginMs) % 1000 === 0) {
                console.warn(`_LevelupDB.getDate() (lastDateMs) is ahead of Date.now() by ${(updatedMs - beginMs) / 1000} seconds`)
            }
        }
        return convertToCanonicalDateTimeFormat(new Date(updatedMs))
    }

    cancel() {
    }
    
    slice() {
        throw Error('Only supported in _MemoryDB for unit testing')
        return []
    }
    
    reset(nextId: number) {
        throw Error('Only supported in _MemoryDB for unit testing')
    }

    get fullDbName() { return `level-js-${this.name}-db` }

    async deleteDB(): Promise<void> {
        await this.db.close()
        return new Promise((resolve, reject) => {
            let deleteRequest = indexedDB.deleteDatabase(this.fullDbName)

            deleteRequest.onerror = event => { reject('Error deleting db') }

            deleteRequest.onsuccess = event => { resolve() }

            deleteRequest.onblocked = event => { reject('Delete db request blocked') }
        })
    }
}

interface IChange {
    doc: any,
    timer: null | ReturnType<typeof setTimeout>
}

// Batch all db changes that update the same field(s) of the same object and debounce them.
class DBChangeDeferrer {
    private changes: IChange[] = []

    constructor(private db: _LevelupDB) {
        this.submitChange = this.submitChange.bind(this)
        this.commitChange = this.commitChange.bind(this)
    }

    submitChange(doc: IDBModDoc) {
        // Two changes are equivalent if they have the same _id and all the field
        // names are the same
        function sameItem(doc2: IDBModDoc) {
            if (doc._id !== doc2._id) return false
            
            // A request to remove should we replace any other request.
            // Otherwise the item flashes back into existence when the original commit happens
            // and then re-disappears when the removal commit happens.
            if (doc.removed) return true
            
            let existingDocKeys = Object.keys(doc2).sort()
            let docKeys = Object.keys(doc).sort()
            return JSON.stringify(existingDocKeys) === JSON.stringify(docKeys)
        }

        let existingIndex = this.changes.findIndex(item => sameItem(item.doc))

        if (existingIndex > -1) {
            dbg('submitChange replacement', JSON.stringify(doc))
            let existing = this.changes[existingIndex]
            existing.timer && clearTimeout(existing.timer)
            existing.timer = setTimeout(() => this.commitChange(doc), 5000)
            existing.doc = doc
        } else {
            dbg('submitChange', JSON.stringify(doc))
            let timer = setTimeout(() => this.commitChange(doc), 5000)
            this.changes.push({ doc, timer })
        }
    }

    private async commitChange(doc: any) {
        dbg('commitChange', JSON.stringify(doc))
        await this.db.put(doc) // <-- _LevelupDB.put()

        let index = this.changes.findIndex(c => c.doc === doc)
        if (index > -1) {
            this.changes.splice(index, 1)
        } else {
            log('### commitChange doc not in changes!')
        }
    }
}

const TEST_ERROR_HANDLING_MESSAGE = '!!! TEST ERROR HANDLING'

const isNeedingReporting = (error: unknown) => (error instanceof Error) &&
    (!error.message.startsWith(NonretriableSyncFailure) &&
        error.message !== TEST_ERROR_HANDLING_MESSAGE)

// Returns a UMT date with format 2020/10/03 19:01:14.093Z
export function convertToCanonicalDateTimeFormat(date: Date) {
    let iso = date.toISOString()

    iso = iso.replace('-', '/')
    iso = iso.replace('-', '/') // replace second occurence of -
    iso = iso.replace('T', ' ')

    return iso
}

export function transformDocToLANKey(clientId: string, doc: IDBModDoc): string {
    const lanKey = `${doc.modDate}__${clientId}__${doc.modBy}__${doc._id}`
    if (!isLANKey(lanKey)) {
        throw new Error(`transformDocToLANKey: invalid lanKey: ${lanKey}`)
    }
    if (lanKey <= MIN_LAN_SEQ || lanKey >= MAX_LAN_SEQ) {
        throw new Error(`transformDocToLANKey: lanKey ${lanKey} out of range`)
    }
    return lanKey
}

/**
 * converts an _id tag prefix (e.g. 'plan_') to a 3 digit hash number
 * 
 * See printHashes() in tests for generate3DigitHashNumber() in _LevelupDB.test.ts
 * See also DBAcceptor.listTags and DBAcceptor.projectIdVariants

            project:
                118 teamPreferences
                121 project
                149 members
                164 member
                168 projectPreferences
                206 publicationPreferences
            lists:
                000 [no tag]
                111 ref
                136 gls
                159 tsk
                184 thumbVid
                189 prjImg
                338 hgh
                342 seg
                362 term
                423 plan
                463 `@viewedby`
                753 stg
                821 pasDoc
 * @param tag 
 */
export function convertTagToHash(tag: string, endTag?: string): string {
    const finalTag = `${tag || ''}${endTag ? `@${endTag}` : ''}`
    return Math.abs(tinyHash(finalTag)).toString().substring(0, 3)
}

/**
 * this will produce a 38 (string) character id in the following format to help prevent write collisions across each tabs (especially when offline)
 * ${modDate: YYMMddHHmmsszzz}0${dbId: /[0-9]{4}/}0${idDepth: /[0-9]/}${tagId: /[0-9]{3}/}0${lastIdPart: YYMMddHHmmss}
 * Example: 24121202471191601234017530241212024714
 * - modDate: 241212024711916 (24-12-12 02:47:11 916Z)
 * - dblId - 1234, this number gets generated whenever a page refreshes or sltt is loaded in a new window/tab
 * - idDepth - 1 this is a number that represents the `/` depth of the _id (e.g. plan_241212_024713/stg_241212_024714)
 * - tagId - 753 (stg). This is a 3-character numerical hash of an _id tag (e.g. /stg_241212_024714)
 * - lastIdPart - 241212024714 (241212_024714) this together with the tagId helps prevent overwriting docs with different _ids in the case of bulk operations in different tabs/windows that may produce several docs
 * @param doc
 * @param dbId 
 * @returns string seq 38 digits long which can be converted to a (BigInt) number
 */
export function generateLocalBrowserSeq(doc: IDBModDoc, dbId: string) {
    const parsedLevelUpId = Number.parseInt(dbId)
    if (Number.isNaN(parsedLevelUpId)) {
        throw new Error(`dbId ${dbId} is NaN`)
    }
    if (parsedLevelUpId > 9999) {
        throw new Error(`dbId ${dbId} > 9999`)
    }
    const modDateAsNumber = new Date(doc.modDate).toISOString()
        .replace(/[-:T\.Z]/g, '')
        .substring(2)
    // get last id part and remove any tag_ prefix
    // however, it's possible that the last part is just a string (e.g. 'project') not a date_time string
    // in that case, we can convert it to a 10 digit number using tinyHash
    const idDepth = doc._id?.split('/').length - 1
    const finalIdParts = doc._id?.split('/').slice(-1)[0].split('_')
    const [finalIdPart, endTag] = finalIdParts[finalIdParts.length - 1].split('@')
    finalIdParts[finalIdParts.length - 1] = finalIdPart
    let tag = ''
    let date
    let hhmmss
    if (finalIdParts.length === 1) {
        [tag] = finalIdParts
    } else if (finalIdParts.length === 2) {
        [date, hhmmss] = finalIdParts
    } else if (finalIdParts.length === 3) {
        [tag, date, hhmmss] = finalIdParts
    }
    let sfinalTagNum = `000` 
    let sfinalDateTime = `000000000000`
    if (tag || endTag) {
        sfinalTagNum = convertTagToHash(tag, endTag)
    }
    if (date) {
        sfinalDateTime = `${date}${hhmmss}`
    }
    //                15 (12+3)     1  4    1      1          3       1       12        = 15+1+4+2+3+1+12 = 38
    const sseq = `${modDateAsNumber}0${dbId}0${idDepth}${sfinalTagNum}0${sfinalDateTime}`
    if (sseq <= MIN_LOCAL_SEQ || sseq >= MAX_LOCAL_SEQ) {
        throw new Error(`sseq ${sseq} out of range`)
    }
    if (sseq.length !== expectedLocalBrowserKeyLength) {
        throw new Error(`sseq.length (${sseq.length}) !== ${expectedLocalBrowserKeyLength}: ` + sseq)
    }
    const seq = Number.parseInt(sseq)
    if (Number.isNaN(seq)) {
        throw new Error(`seq is NaN: ` + sseq)
    }
    return sseq
}

export function extractDbIdFromSeq(seq: string): string {
    if (!isNewLocalBrowserKey(seq)) throw new Error(`Expected seq '${seq}' to be isNewLocalBrowserKey`)
    return seq.substring(16, 20)
}

const _window = window as any
// allow console.log access to _LevelupDB
_window._LevelupDB = _LevelupDB
