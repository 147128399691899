// tinyHash is adapted from https://stackoverflow.com/a/52171480
// The purpose of tinyHash is to not store personal information in the key
// according to https://docs.launchdarkly.com/sdk/features/user-context-config 
// "Keys must be a string type. Keys must be unique, deterministic, 
// and should not contain personally identifiable information (PII). 
// Keys must be consistent, which means the same person must correspond 
// to the same key across different services to contribute to consistent 
// flag evaluations and accurate monthly context instances (MCI). 
// You can use a primary key or a hash, as long as the same person 
// always has the same key. We recommend using a hash if possible.
/**
 * @param s 
 * @returns 10 digit numerical hash (might also have a negative symbol, e.g. -1494561815)
 */
export const tinyHash = (s: string) => {
    let h = 9
    for (let i = 0; i < s.length;)
        h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9)
    return h ^ h >>> 9
}
